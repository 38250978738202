@import "../styles/_colors.scss";
@import "../styles/_layouts.scss";

$close-button-height: 25px;

.modal-container {
    background-color: #00000073;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
}

.modal {
    margin: 0;
    position: relative;
    left: 50%;
    top: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border-radius: $radius;
    width: 80%;
    background-color: $foreground-color;
    box-shadow: $shadow;
    padding: $pad;
    padding-top: $pad + $close-button-height;
    box-sizing: border-box;
}

.close-button {
    position: absolute;
    right: $pad;
    top: $pad;
    height: $close-button-height;
    background-color: $foreground-color;
    font-size: $close-button-height;
    line-height: $close-button-height;
    font-weight: 700;
}
