@import "styles/_reset.scss";
@import "styles/_fonts.scss";
@import "styles/_colors.scss";
@import "styles/_layouts.scss";

body,
html,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Poppins, sans-serif;
  color: $text-primary;
}

html {
  width: 100%;
  height: 100%;
  margin: 0 !important;
  padding: 0 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $footer-background;
}
