@import "_colors.scss";
@import "_layouts.scss";

$checkbox-width: 25px;
$check-pad: 10px;

.ais-RefinementList-label {
    display: block;
    position: relative;
    padding-left: $checkbox-width + $check-pad;
    margin: $check-pad 0;
    cursor: pointer;
    line-height: $checkbox-width;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.ais-RefinementList-checkbox {
    position: absolute;
    top: 0;
    left: 0;
    height: $checkbox-width;
    width: $checkbox-width;
    cursor: pointer;
}

.ais-ClearRefinements-button {
    background-color: $secondary;
}

.ais-Hits-item {
    width: 100%;
    border: none;
    margin: 0;
    box-shadow: none;
    padding-left: 0;
    padding-right: 0;
}

.ais-Hits-item:not(:last-child) {
    border-bottom: 1px solid #efefef;
}

.ais-Hits-list {
    margin: 0;
}

.ais-Pagination-item--selected .ais-Pagination-link {
    background-color: $secondary;
    border-color: $secondary;
}
