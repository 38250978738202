@import "../styles/_colors.scss";
@import "../styles/_layouts.scss";

.logo {
    height: 200px;
    text-align: center;
    line-height: 200px;
}

h1 {
    font-weight: 400;
    padding: $pad 0;
}

.splash-container {
    padding-bottom: 100px;
    text-align: center;
}

@media screen and (max-width: 600px) {
    .logo {
        height: 100px;
        line-height: 100px;
    }
}
