@import "../styles/_colors.scss";
@import "../styles/_layouts.scss";

.school-container {
    display: flex;
    padding: $pad;

    .details {
        flex-grow: 5;

        h2 {
            font-weight: 700;
            font-size: 1.5em;
            margin: $pad 0;
        }
    }

    .actions {
        min-width: 130px;

        .action {
            display: block;
            width: 100%;
            margin: $pad 0;
            padding: $pad;
            border-radius: $radius;
            letter-spacing: -0.5px;
        }
        
        a {
            text-decoration: none;
        }

        .primary {
            background-color: $primary;
            color: white;
        }
    }
}
