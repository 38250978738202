@import "../styles/_colors.scss";
@import "../styles/_layouts.scss";

$refine-width: 250px;

.logo {
    height: 50px;
}

h4 {
    margin-bottom: $pad;
    font-size: 1.2rem;
}

.left-panel {
    padding: $pad*2 0;
    width: $refine-width;
    float: left;
}

.right-panel {
    margin-left: $refine-width + $pad * 2;
}

.refine-section {
    border-style: solid;
    border-width: 1px;
    border-color: #efefef;
    border-radius: 6px;
    padding: $pad;
    margin: $pad 0;
}

.always-clear {
    clear: both;
}

@media screen and (max-width: 700px) {
    .left-column {
        width: 100%;
    }

    .right-panel {
        margin-left: 0;
    }

    .mobile-clear {
        clear: both;
    }
}
