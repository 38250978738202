@import "../styles/_colors.scss";
@import "../styles/_layouts.scss";


.page-container {
    width: 100%;
    background-color: $background-color;
    box-sizing: border-box;
    padding: $pad;
    margin-right: auto;
    margin-left: auto;
}

.page-card {
    padding: $pad;
    box-sizing: border-box;
    margin: 0 auto;
    max-width: $max-width;
    margin-bottom: -50px;
    width: 100%;
    background-color: $foreground-color;
    border-radius: $radius;
    box-shadow: $shadow;
}
