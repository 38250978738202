@import "../styles/_colors.scss";
@import "../styles/_layouts.scss";

$search-height: 22px;

.search-container {
    display: flex;
    padding: $pad 0;
}

.search-icon {
    margin: $pad/2 $pad 0 0;
    height: $search-height + $pad/2;
    color: $text-secondary;
}

.search-input {
    flex-grow: 1;
    font-size: $search-height;
    line-height: $search-height + $pad;
    border: 0;
    border-bottom: 2px solid $text-secondary;
    margin-right: $pad;
}
