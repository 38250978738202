@import "../styles/_colors.scss";
@import "../styles/_layouts.scss";

.input-row {
    box-sizing: border-box;
    padding: $pad 0;
    font-size: 1.2em;
    label {
        display: block;
        padding-bottom: $pad;
    }
    input {
        display: block;
        width: calc(100% - 4px); // fix for 2px of padding on each side
        height: 2.5em;
        border: 0;
        border-bottom: 2px solid $text-secondary;
    }
    select {
        display: block;
        width: 100%;
        height: 2.5em;
    }
}

.submit {
    float: right;
    margin: $pad 0;
    padding: $pad;
    background-color: $primary;
    color: white;
    border-radius: $radius;
    letter-spacing: -0.5px;
}

.action {
    float: right;
    margin: $pad;
    padding: $pad;
    background-color: $secondary;
    color: white;
    border-radius: $radius;
    letter-spacing: -0.5px;
}
