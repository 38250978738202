@import "../styles/_colors.scss";
@import "../styles/_layouts.scss";

.footer-container {
    color: $text-secondary;
    width: 100%;
    box-sizing: border-box;
    padding: $pad;
    margin-right: auto;
    margin-left: auto;
}

.footer {
    padding: $pad;
    box-sizing: border-box;
    padding-top: 30px;
    margin: 0 auto;
    max-width: $max-width;
}
