$radius: 4px;
$shadow: 0 30px 50px 0 rgba(12,0,46,.1);

$pad: 15px;
$max-width: 1080px;

.spacer {
    height: 100px;
}

@media screen and (max-width: 600px) {
    .spacer {
        height: 20px;
    }
}

.clear-both {
    clear: both;
}
