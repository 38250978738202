@import "../styles/_colors.scss";
@import "../styles/_layouts.scss";

.option-card {
    min-height: 120px;
    border-style: solid;
    border-width: 1px;
    border-color: #efefef;
    border-radius: 6px;
    margin: 10px;
    vertical-align:top;
    justify-content: center;
    padding: $pad;
    width: 40%;
    display: inline-block;
    box-sizing: border-box;
    line-height: 1.5rem;

    h3 {
        color: $primary;
        font-size: 1.5rem;
        padding: $pad 0;
        font-weight: 700;
    }

    p {
        color: $text-secondary;
        padding: $pad 0;
        font-weight: 300;
    }

    button {
        border-radius: $radius;
        background-color: $primary;
        color: white;
        padding: $pad;
        margin: $pad;
        letter-spacing: -0.5px;
        font-size: 1.1em;
        box-shadow: 0 10px 10px 0 rgba(61,100,244,.16);
    }

    button:hover {
        box-shadow: none;
        transition: box-shadow 0.3s ease-in-out;
    }
}

@media screen and (max-width: 600px) {
    .option-card {
      width: inherit;
    }
}

